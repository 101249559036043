

export const NabItems = [
    {
        name : "Dashbord",
        link : "/",
        href : "/",
    },
    {
        name : "Orders",
        link : "/orders",
        href : "/orders",
    },
    {
        name : "Products",
        link : "/products",
        href : "/products",
    },
    {
        name : "Categories",
        link : "/categories",
        href : "/categories",
    },
    {
        name : "Admins",
        link : "/admins",
        href : "/admins",
    },
]
import "./Dashboard.scss";
import { useState, useEffect } from "react";
import Loder from "../../Components/Loder/Loder";
import PageHeader from "../../Components/PageHead/PageHeader";
import { useNavigate } from "react-router-dom";
import Profile from "../../Components/AdminProfile/Profile";
import CardBox from "../../Components/DashboardCrads/CardBox";
import { MdAddCircle } from "react-icons/md";
import Swal from "sweetalert2";
import { addProductCategory, getPrices, getAdminToken, getDashboardData } from "../../Firebase/APIS";

function Dashboard() {
  const navigate = useNavigate();
  const [dashData, setDashData] = useState(false);
  const [rates, setRates] = useState(false);

  async function getDashboardDatas() {
    const result = await getDashboardData(getAdminToken())
    if (result.success) {
      setDashData(result.count)
    }
    const rateResult = await getPrices();
    if(rateResult.success) {
      setRates(rateResult.prices)
    }
  }
  
  useEffect(() => {
    getDashboardDatas();
  }, []);

  const AddCategory = () => {
    Swal.fire({
      title: "Add Category",
      input: "text",
      inputLabel: "Enter New Category Name",
      showCancelButton: true,
      preConfirm: async (value) => {
        if (!value) {
          Swal.showValidationMessage("You need to write something!");
          return;
        }
        const result = await addProductCategory(getAdminToken(), {
          name: value,
        });
        if (result?.success) {
          Swal.fire({
            title: "Good Job!",
            text: result.message,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Oops...!",
            text: result?.message,
            icon: "error",
          });
        }
      },
    });
  };

  return (
    <div className="dashboardComponents">
      <div className="dashboardContainer">
        <PageHeader page={"Dashboard"} />
        <div className="btnContainer">
          <p onClick={() => navigate("/register")} className="btnItem">
            <MdAddCircle className="icon" />
            Add New Admin
          </p>
          <p onClick={AddCategory} className="btnItem">
            <MdAddCircle className="icon" />
            Add Category
          </p>
          <p onClick={() => navigate("/product/add-price")} className="btnItem">
            <MdAddCircle className="icon" />
            Add Price
          </p>
          <p
            onClick={() => navigate("/product/add-product")}
            className="btnItem"
          >
            <MdAddCircle className="icon" />
            Add New Product
          </p>
        </div>
        <div className="dashboardtableContainer">
          {dashData ? (
            <div className="contentBox">
              <Profile adminData={dashData?.adminData} rates={rates} />
              <CardBox cardData={dashData} rates={rates}/>
            </div>
          ) : (
            <Loder />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
